import React, { useEffect } from "react";
import "./Info.css";

import Aos from "aos";
import "aos/dist/aos.css";

const Info = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className="info" id="projects">
      <h1>פרויקטים של חברה</h1>
      <div className="info-flex" data-aos="zoom-in-down">
        <div className="few-info">
          <h4>גמידה - סל</h4>
          <p>
          מערכת מיזוג לחדרים נקיים, מעבדות ומשרדים.
            מערכת אינסטלציה למעבדות וחדרים נקיים
          </p>
        </div>

        <div className="few-info">
          <h4>אנלייבקס</h4>
          <p>
          מערכת מיזוג לחדרים נקייה, מערכת אינסטלציה למעבדות וחדרים נקיים
          </p>
          <h4> רווח-אגרו</h4>
          <p>מערכת מיזוג לחדר ייבוש קנאביס רפואי</p>
        </div>

        <div className="few-info">
          <h4>הדסה עין כרם - שיפוץ חדרי ניתוחי עיניים</h4>
          <p>מערכת מיזוג אוויר, מערכת אינסטלציה</p>
          <h4>הדסה עין כרם - מרפאות בבית שמש</h4>
          <p>מערכת מיזוג אוויר, מערכת אינסטלציה</p>
        </div>

        <div className="few-info">
          <h4>אספקה ​​ותכשירים סטריליים בית מרקחת - אסף הרופא</h4>
          <p>מערכת מיזוג אוויר, מערכת אינסטלציה</p>
          <h4>מעבדת הבנק הלאומי של קורונה</h4>
          <p>מערכת מיזוג אוויר, מערכת אינסטלציה</p>
        </div>

        <div className="few-info">
          <h4>מיון רפואי דחוף לילדים - שיבא טל השומר</h4>
          <p>מערכת מיזוג אוויר</p>
          <h4>מחלקת הילדים - שיבא תל השומר</h4>
          <p>שדרוג מערכת אינסטלציה</p>
          <h4>שיבא תל השומר - אספקה ​​סטרילית</h4>
          <p>מערכת מיזוג אוויר, מערכת אינסטלציה</p>
        </div>
      </div>
      {/* <div className="seperator"></div> */}
    </div>
  );
};

export default Info;
