import React, { useEffect } from "react";
import "./Buildings.css";

const Buildings = () => {
  return (
    <div className="main-sky">
      <div className="left-sky">
        <h1>
          הדסה עין
          <br /> כרם - החדרים שלי <br /> כִּירוּרגִיָה
        </h1>
      </div>
      <div className="right-sky">
        <h4>מערכות: </h4>
        <p>
          מ.א. - תכנון מערכת מיזוג לחדרי ניתוח, ל-3 חדרי ניתוח, מסדרון סטרילי
          וחדרי שירות. CO2, O2, MA, N2, VAC : מערכת אינסטלציה של גזים רפואיים
          EVAC פינוי גז הרדמה מערכת, מי חולה, צריכת מים חמים, סניטציה.
        </p>
        <h4>תִכנוּן</h4>
        <p>החלפה והתאמה של מערכות בנות 40 שנה לזרם תקנים</p>
        <h4>הקבוצה</h4>
        <p>עבודה עם מנהל הפרויקט בתיאום עם יועצים: אדריכלות, חשמל ואינסטלציה</p>
      </div>
    </div>
  );
};

export default Buildings;
