import React from "react";
import { Link } from "react-scroll";
import "./Header.css";
import logo from "../../images/logo.png";

const Header = () => {
  return (
    <header id="hero">
      <nav className="nav">
        <input type="checkbox" id="nav-check" />
        <div className="nav-header">
          <div className="nav-title">
            <a href="#">
              <img className="logo" src={logo} alt="company-logo"/>{" "}
            </a>
          </div>
        </div>
        <div className="nav-btn">
          <label htmlFor="nav-check">
            <span></span>
            <span></span>
            <span></span>
          </label>
        </div>

        <div className="nav-links">
          <Link to="hero" spy={true} smooth={true} offset={0} duration={500}>
          בית
          </Link>
          <Link to="about" spy={true} smooth={true} offset={-100} duration={500}>
          עלינו
          </Link>
          <Link to="projects" spy={true} smooth={true} offset={-10} duration={500}>
          פרויקטים
          </Link>
          <Link to="contact" spy={true} smooth={true} offset={-150} duration={500}>
          איש קשר
          </Link>
        </div>
      </nav>
      <div className="hero">
        <div className="hero-text">
          <h2>הבחירה הטובה ביותר</h2>
          <p>
            "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet,
            consectetur, adipisci velit..." "There is no one who loves pain
            itself, who seeks after it and wants to have it, simply because it
            is pain..."
          </p>
        </div>
      </div>
      <span className="seperator"></span>
    </header>
  );
};

export default Header;
