import React, { useEffect } from "react";
import "./Works.css";
import rooms1 from "../../images/rooms1.jpg";
import rooms2 from "../../images/rooms2.jpg";
import rooms3 from "../../images/rooms3.jpg";

import Aos from "aos";
import "aos/dist/aos.css";

const Works = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className="works">
      <div className="illustration" />
      <div className="secillustration" />
      <h1>פרויקט אספקה ​​סטרילית ותכשירים פרמצבטיים - אסף הרופא</h1>
      <div className="flexy-div">
        <div className="f-flex" data-aos="fade-up">
          <img src={rooms1} alt="places-to-work" />
          <h4>תכנון באמצעות BIM</h4>
          <p>
            הפרויקט תוכנן בתלת מימד תוך כדי אינטגרציה של כל המערכות: MA,
            אינסטלציה, מכונות ועוד. התכנון כללה תיאום מערכת.גיוון במרחב עבודה
            מצומצם
          </p>
        </div>

        <div className="f-flex bordered" data-aos="fade-down">
          <img src={rooms2} alt="places-to-work" />
          <h4>מזגן</h4>
          <p>
            תכנון מערכת מיזוג אוויר בשיטה 4 צינורות עבור א תרכובת אספקה
            ​​סטרילית, חדרים נקיים לתכשירים פרמצבטיים ציטוטוקסיות, מעבדות
            ומשרדים.
          </p>
        </div>

        <div className="f-flex" data-aos="fade-up">
          <img src={rooms3} alt="places-to-work" />
          <h4>אינסטלציה ומערכות</h4>
          <p>
            תכנון מערכת אינסטלציה: שפכים, מים רשתות ומים חמים, קיטור, אוויר
            דחוס, מים מטופלים - RO. תכנון מערכת התקנת מכונות עיקור וכביסה
          </p>
        </div>
      </div>
      <div className="seperator"></div>
    </div>
  );
};

export default Works;
