import React, { useEffect } from "react";
import "./Testimonials.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Aos from "aos";
import "aos/dist/aos.css";

import slider1 from "../../images/slider1.jpg";
import slider2 from "../../images/slider2.jpg";
import slider3 from "../../images/slider3.jpg";
import slider4 from "../../images/slider4.jpg";
import slider5 from "../../images/slider5.jpg";

const Testimonials = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="carousel">
      <div className="seperator"></div>
      <div className="portfolio">
        {/* HEADING */}
        <span>עבודות אחרונות</span>
        <span>תיק עבודות</span>

        {/* SlIDER */}
        <Swiper
          spaceBetween={20}
          slidesPerView={3}
          grabCursor={true}
          className="portfolio-slider"
          data-aos="fade-up"
        >
          <SwiperSlide>
            <img src={slider1} alt="company-projects" />
          </SwiperSlide>

          <SwiperSlide>
            <img src={slider2} alt="company-projects" />
          </SwiperSlide>

          <SwiperSlide>
            <img src={slider3} alt="company-projects" />
          </SwiperSlide>

          <SwiperSlide>
            <img src={slider4} alt="company-projects" />
          </SwiperSlide>

          <SwiperSlide>
            <img src={slider5} alt="company-projects" />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="paragraph">
        <div className="slider-text">
          <h4>תכנון ודוגמנות</h4>
          <p>
            ליווי הלקוח בשלב פיתוח הקונספט עבור תכנון מפורט שעומד בכל הדרישות
            והאילוצים
          </p>
        </div>
      </div>
      <span className="seperator"></span>
    </div>
  );
};

export default Testimonials;
