import React, { useEffect } from "react";
import "./Green.css";
import green1 from "../../images/green1.jpg";
import green2 from "../../images/green2.jpg";
import green3 from "../../images/green3.jpg";
import green4 from "../../images/green4.jpg";

const Green = () => {
  return (
    <div className="green">
      <div className="decor" />
      <div className="secdecor" />
      <div className="main-green">
        <div className="img-green">
          <div className="img-grid">
            <div>
              <img src={green1} alt="projects-examples" />
            </div>
            <div>
              <img src={green2} alt="projects-examples" />
            </div>
          </div>
          <div className="img-grid">
            <div>
              <img src={green3} alt="projects-examples" />
            </div>
            <div>
              <img src={green4} alt="projects-examples" />
            </div>
          </div>
        </div>
        <div className="green-text">
          <p>גזרי מתקן גידול פנימי, פרחי חדר וכו'.</p>
          <p>
            חדרי ייבוש לתפוקה של 3 טון לשבוע בתנאי של 18 מעלות צלזיוס ו-%50 לחות
          </p>
          <p>מתקן עיבוד אריזה ומיצוי</p>
        </div>
      </div>
    </div>
  );
};

export default Green;
