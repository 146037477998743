import React, { useEffect } from "react";
import "./AboutUs.css";
import map from "../../images/map.png";
import Aos from "aos";
import "aos/dist/aos.css";

import woman from "../../images/woman.jpg";
import man from "../../images/man.jpg";

const AboutUs = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="aboutus">
      <h1 className="people">צוות החברה מורכב ממהנדסים בעלי התמחויות שונות</h1>
      <img className="map" src={map} alt="img" />
      <div className="container">
        <div className="leftCol">
          <img src={woman} alt="co-worker" />
          <div data-aos="fade-left" className="divy">
            <h1>מאי קוזמן</h1>
            <p>מהנדס אדריכלות ועיצוב פנים. בוגר שנקר רכז מערכות ב-BIM</p>
          </div>
        </div>
        <div className="rightCol">
          <div data-aos="fade-right" className="divy">
            <h1>מקס קוזמן</h1>
            <p>
              מהנדס מכונות בוגר אוניברסיטת תל אביב. יוֹעֵץ למערכות מיזוג
              ואינסטלציה
            </p>
          </div>
          <img src={man} alt="co-worker" />
        </div>
      </div>
      <span className="seperator"></span>
    </div>
  );
};

export default AboutUs;
