import React from "react";

const Builds = () => {
  return (
    <div className="main-sky">
      <div className="left-sky">
        <h1>
          אספקה ​​סטרילית - <br /> שיבא טל <br />
          השומר
        </h1>
      </div>
      <div className="right-sky">
        <h4>מערכות:</h4>
        <p>
          MA - תכנון מערכת מיזוג אוויר בשיטת 4 צינורות, עבור אולם אספקה
          ​​סטרילית הכוללת: אולם טעינה נקי, הפצה מחסן פריקה סטרילית , אוויר
          דחוס, מים אינסטלציה - קיטור מערכת, מים עיבוי חולים, מי שתייה, מערכת
          ספרינקלרים ו תברואה לטובת מערכת אספקה ​​סטרילית הכוללת מכונות חיטוי,
          ETO וכו'.
        </p>
        <h4>תִכנוּן</h4>
        <p>
          המערכות תוכננו תוך זמן קצר של 3 שבועות עקב קריסה יש סט קיים של
          אוטוקלבים ודחוף לצאת אליו מִכרָז
        </p>
        <h4>הקבוצה</h4>
        <p>עבודה מול מנהל פרויקט בתיאום עם יועצים: אדריכלות, חשמל ואינסטלציה</p>
      </div>
    </div>
  );
};

export default Builds;
