import React from "react";
import "./App.css";
import Header from "./Components/Header/Header";
import AboutUs from "./Components/AboutUs/AboutUs";
import Testimonials from "./Components/Testimonials/Testimonials";
import Footer from "./Components/Footer/Footer";
import AboutCo from "./Components/AboutCo/AboutCo";
import Buildings from "./Components/Buildings/Buildings";
import Works from "./Components/Works/Works";
import Info from "./Components/Info/Info";
import Builds from "./Components/Builds/Builds";
import Links from "./Components/Links/Links";
import Green from "./Components/Green/Green";
import Carousel from "./Components/Carousel/Carousel";

function App() {
  return (
    <div className="App">
      <Header />
      <AboutCo />
      <AboutUs />
      <Buildings />
      <Works />
      <Carousel />
      <Info />
      <Builds />
      <Links />
      <Green />
      <Testimonials />
      <Footer />
    </div>
  );
}

export default App;
