import React from "react";
import "./Footer.css";
import wave from "../../images/wave.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <footer id="contact">
      <div className="footerDiv">
        <img src={wave} alt="wave" />
        <div className="infoDiv">
          <ul>
            <li>
              <FontAwesomeIcon
                className="icony"
                icon={faPhone}
              ></FontAwesomeIcon>
              Phone : +972-50-495-0777
            </li>
            <li>
              <FontAwesomeIcon
                className="icony"
                icon={faEnvelope}
              ></FontAwesomeIcon>
              Email : maxkozman@gmail.com
            </li>
            <li>
              <FontAwesomeIcon
                className="icony"
                icon={faAddressCard}
              ></FontAwesomeIcon>
              Address : חן 15, אבן יהודה
            </li>
          </ul>
        </div>
        <p className="copyright">
          Copyright
          <FontAwesomeIcon
            className="icony"
            icon={faCopyright}
          ></FontAwesomeIcon>
          by ColWebry 2023
        </p>
      </div>
    </footer>
  );
};

export default Footer;
