import React, { useEffect } from "react";
import "../Testimonials/Testimonials.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Aos from "aos";
import "aos/dist/aos.css";
import "./Carousel.css";
import schema1 from "../../images/schema1.png";
import schema2 from "../../images/schema2.png";
import schema3 from "../../images/schema3.png";
import schema4 from "../../images/schema4.png";
import schema5 from "../../images/schema5.png";
import latest1 from "../../images/latest1.png";
import latest2 from "../../images/latest2.png";

const Carousel = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className="carousel">
      <div className="portfolio">
        {/* HEADING */}
        <span>השיטות שלנו</span>
        <span>תיק עבודות</span>

        {/* SlIDER */}
        <Swiper
          spaceBetween={20}
          slidesPerView={3}
          grabCursor={true}
          className="portfolio-slider"
          data-aos="fade-up"
        >
          <SwiperSlide>
            <img src={schema1} alt="company-projects" />
          </SwiperSlide>

          <SwiperSlide>
            <img src={schema2} alt="company-projects" />
          </SwiperSlide>

          <SwiperSlide>
            <img src={schema3} alt="company-projects" />
          </SwiperSlide>

          <SwiperSlide>
            <img src={schema4} alt="company-projects" />
          </SwiperSlide>

          <SwiperSlide>
            <img src={schema5} alt="company-projects" />
          </SwiperSlide>
        </Swiper>
      </div>

      <div className="latest-projects">
        <h1>של החברה שלנו</h1>
        <h2>הפרויקטים האחרונים</h2>
        <div className="latest">
          <img src={latest1} alt="latest-projects" id="reverse"/>
        </div>
        <div className="latest">
          <img src={latest2} alt="latest-projects" />
        </div>
      </div>
    </div>
  );
};

export default Carousel;
