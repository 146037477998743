import React, { useEffect } from 'react'
import './Links.css';
import labs1 from '../../images/labs1.jpg'
import labs2 from '../../images/labs2.jpg'
import labs3 from '../../images/labs3.jpg'


import Aos from "aos";
import "aos/dist/aos.css";


const Links = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className='links-main'>
        <div className='effect'/>
        <div className="links-left">
            <h4>פרויקט חמידה סל</h4>
            <p>תכנון ותיאום מערכות מיזוג ואינסטלציה</p>
            <h5>מערכות:</h5>
            <ul>
                <li>700 מ"ר חדרים נקיים ברמות ניקיון ב', ג', ד'</li>
                <li>100,1 מ"ר של מעבדות ואזורי הפעלה מבוקרים</li>
                <li>900 מ"ר משרדים</li>
                <li>מרכז אנרגיה 900 טון קירור</li>
                <li>מערכת גז נקי: CO2, שמן אוויר דחוס חופשי</li>
                <li>מערכת ספרינקלרים וברזים</li>
                <li>תכנון באמצעות BIM</li>
            </ul>
        </div>
        <div className="links-right" data-aos="fade-left">
           <div>
           <img src={labs1} alt="workplaces" />
           </div>
           <div>
           <img src={labs2} alt="workplaces" />
           </div>
           <div>
           <img src={labs3} alt="workplaces" />
           </div>
            
        </div>
    </div>
  )
}

export default Links