import React, { useEffect } from "react";
import "./AboutCo.css";
import screens1 from "../../images/screens1.png";
import screens2 from "../../images/screens2.png";
import screens3 from "../../images/screens3.png";

import Aos from "aos";
import "aos/dist/aos.css";

const AboutCo = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="company" id="about">
      <div className="left-company">
        <div className="company-imgs" data-aos="fade-up">
          <div className="flex-company">
            <img src={screens1} alt="company-works" />
            <img src={screens2} alt="company-works" />
          </div>
          <div className="single">
            <img src={screens3} alt="company-works" />
          </div>
        </div>
      </div>
      <div className="right-company" data-aos="fade-left">
        <h1>
          מי אנחנו ומה
          <br /> אנחנו עושים
        </h1>
        <p>
          שירותי תכנון מערכות הנדסיות עבור עתירי ידע תעשיות הדורשות סביבה מבוקרת
          כגון: בתי חולים, מעבדות ומפעלים
        </p>
        <span className="underline"></span>
        <p>
          אפיון דרישות הפרויקט מול הלקוח תוך כדי הבנה מעמיקה של דרישות
          רגולטוריות
        </p>
        <span className="underline"></span>

        <p>תכנון באמצעות מידול תלת מימד (BIM) כולל תיאום מערכת</p>
        <span className="underline"></span>
        <p>שילוב התמחויות: אינסטלציה הנדסית מיזוג אוויר והנדסת תהליכים</p>
        <span className="underline"></span>

        <p>מתן פתרונות יצירתיים לאתגרים הנדסיים</p>
        <span className="underline"></span>
      </div>
    </div>
  );
};

export default AboutCo;
